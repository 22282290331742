export default ({ code, InputGroupIconPassword, InputGroupTypePassword, InputGroupIconRepeat, InputGroupTypeRepeat }) => {
  

  const passwordFields = [
    {
      id: 'password',
      model: 'password',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Nova Senha',
        type: InputGroupTypePassword,
        floatingLabel: true,
        invalidLabel: 'Senha inválida',
        autoFillColor: 'primary-base',
        theme:'light',
        icon: InputGroupIconPassword
      },
      events: {
        click: 'showPassword'
      }
    },
    {
      id: 'repeat_password',
      model: 'repeat_password',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Confirmar Senha',
        type: InputGroupTypeRepeat,
        floatingLabel: true,
        invalidLabel: 'Senha inválida',
        autoFillColor: 'primary-base',
        theme:'light',
        icon: InputGroupIconRepeat
      },
      events: {
        click: 'showRepeatPassword',
        blur: 'lostFocus'
      }
    },
  ]

  return [
    {
      id: 'email',
      model: 'email',
      tag: 'InputGroup',
      rules: ['required', 'email'],
      props: {
        label: 'E-mail',
        type: 'email',
        floatingLabel: true,
        invalidLabel: 'E-mail inválido',
        autoFillColor: 'primary-base',
        theme:'light',
        readOnly: code
      }
    },
    
    ...(code ? passwordFields : []),

    {
      id: 'cancel',
      tag: 'BaseButton',
      props: {
        label: 'Cancelar',
        theme: 'simple',
        color: 'white'
      },
      events: {
        click: 'cancel'
      }
    },
    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: 'Enviar',
        theme: 'icon-right',
        color: 'white',
        icon: 'chevron-right-alt',
        type: 'submit'
      }
    }
  ]
}