<template>
  <section class="ForgotPasswordView">
    <BaseLogo class="ForgotPasswordView__logo" color="white" variation="olistPax" />
    <h1 class="ForgotPasswordView__title">
      Digite abaixo o seu e-mail de cadastro para receber o link para redefinir a sua senha.
    </h1>
    <aside class="ForgotPasswordView__image" :style="ForgotPasswordBackground" />

    <DynamicForm
      ref="form"
      class="ForgotPasswordView__form"
      :class="componentClasses"
      :model="form"
      :blueprint="blueprint({code, InputGroupIconPassword, InputGroupTypePassword, InputGroupIconRepeat, InputGroupTypeRepeat})"
      :endpoint-errors="endpointErrors"
      @cancel="cancel"
      @showPassword="showPassword"
      @showRepeatPassword="showRepeatPassword"
      @lostFocus="validRepeatPassword"
      @submit="onSubmit"
    />
    <Dialog
      v-if="show"
      warning="Ops! As senhas não estão iguais!"
      :label-cancel="false"
      @confirm="confirm"
    />
    <Dialog 
      v-if="showSendEndpoint"
      :warning="sendEnpointWarning" 
      :label-cancel="false"
      @confirm="confirmSendEmail(isSendEnpointEmail)"
    />
  </section>
</template>

<script>
import keys from '@/assets/images/auth/keys.png'
import { BaseLogo } from '@/components/atoms'
import { DynamicForm, Dialog } from '@/components/molecules'
import { mapActions } from 'vuex'
import blueprint from './ForgotPasswordForm.blueprint'

export default {
  name:'ForgotPasswordView',
  components: { BaseLogo, DynamicForm, Dialog },
  data() {
    return {
      hiddenPassword: true,
      hiddenRepeatPassword: true,
      show: false,
      showSendEndpoint: false,
      sendEnpointWarning: '',
      isSendEnpointEmail: false,
      form: {
        email: this.$route.query.email ? this.$route.query.email : '',
        password: '',
        repeat_password: ''
      },
      endpointErrors: {}
    }
  },
  computed: {
    InputGroupIconPassword() {
      return this.hiddenPassword ? 'hide' : 'show'
    },
    InputGroupTypePassword() {
      return this.hiddenPassword ? 'password' : 'text'
    },
    
    InputGroupIconRepeat() {
      return this.hiddenRepeatPassword ? 'hide' : 'show'
    },
    InputGroupTypeRepeat() {
      return this.hiddenRepeatPassword ? 'password' : 'text'
    },

    ForgotPasswordBackground() {
      return { backgroundImage: 'url(' + keys + ')' }
    },
    code() {
      return this.$route.query.code
    },
    email(){
      return this.$route.query.email
    },
    componentClasses(){
      return this.code ? 'ForgotPasswordView__form--token' : 'ForgotPasswordView__form--mail'
    },
  },
  methods: {
    blueprint,

    ...mapActions({
      passwordRecover: 'auth/passwordRecover',
      resetPassword: 'auth/resetPassword',
    }),

    showPassword() {
      this.hiddenPassword = !this.hiddenPassword
    },

    showRepeatPassword() {
      this.hiddenRepeatPassword = !this.hiddenRepeatPassword
    },

    confirm(){
      this.show = false
    },
    confirmSendEmail(isSendEnpointEmail) {
      return isSendEnpointEmail 
        ? this.showSendEndpoint = false
        : this.$router.push('/')
    },

    validRepeatPassword(){
      this.show = this.form.password === this.form.repeat_password ? false : true  
    },

    cancel(){
      this.$router.push('/auth/signin')
    },
    
    async onSubmit(formData) {
      return this.code ? this.onSubmitResetPassword(formData) : this.onSubmitSendEmail()
    },

    async onSubmitSendEmail() {
      const res = await this.passwordRecover(this.form.email)
      this.setMessageAfterResponse(res, res.message, true)
    },
    async onSubmitResetPassword(formData) {
      if (this.form.password === this.form.repeat_password) {
        formData.append('code', this.code)
        let warning = 'Senha redefinida com sucesso!'
        const res = await this.resetPassword(formData)

        this.setMessageAfterResponse(res, warning, false)
      }
    },
    setMessageAfterResponse(res, warning, isSentEmail) {
      if (res.code === undefined) {
        this.sendEnpointWarning = warning
        this.showSendEndpoint = true
        this.isSendEnpointEmail = isSentEmail
      } else {
        this.endpointErrors = res.validation
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ForgotPasswordView {
  background-image: url('../../../assets/images/tracking/bg_login.png');
	background-position: center;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr minmax(min-content, 404px) 1fr;
  grid-template-rows: 2.5rem max-content minmax(200px, 250px) max-content;
  grid-row-gap: 2.5rem;
  grid-template-areas: 
    ". logo  ."
    ". title  ."
    "image image image"
    ". form .";
  padding: 2.5rem;
  @media (max-width: $viewport-xs) {
    padding: 1rem;
  }
  &__logo{
    grid-area: logo;
  }
  &__title{
    grid-area: title;
    text-align: center;
    font-weight: $font-weight-bold;
    color: $color-neutral-softest;
    font-size: $font-size-large;
  }
  &__image {
    grid-area: image;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: .8;
  }
  &__form{
    grid-area: form;
    grid-row-gap: 3rem;
    &--mail{
      grid-template-areas:
        "email email email email email email email email email email email email"
        "cancel cancel cancel cancel cancel cancel confirm confirm confirm confirm confirm confirm";
    }
    &--token{
      grid-template-areas:
        "email email email email email email email email email email email email"
        "password password password password password password password password password password password password"
        "repeat_password repeat_password repeat_password repeat_password repeat_password repeat_password repeat_password repeat_password repeat_password repeat_password repeat_password repeat_password"
        "cancel cancel cancel cancel cancel cancel confirm confirm confirm confirm confirm confirm";
    }
  }
  &__email, &__confirmPassword, &__password{
    margin-bottom: 2.5rem;
  }

  &__actions{
    display: flex;
    justify-content: space-between;
  }

  $btnSize: calc(50% - .625rem);

  &__cancel {
    width: $btnSize;
  }
  &__confirm {
    width: $btnSize;
  }

}
</style>